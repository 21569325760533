<template>
  <v-dialog
      v-if="show"
      v-model="show"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="800"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
  >
    <v-card>
      <v-toolbar class="bg-gradient-primary">
        <v-btn icon @click="closeDialog()">
          <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                  ? "fas fa-chevron-left"
                  : "fas fa-times"
            }}</v-icon>
        </v-btn>
        <v-toolbar-title :style="'color: white;'"
        >{{ currentRecipe.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container fluid class="p-1">
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="8" lg="7" md="7" class="d-flex justify-center">
            <v-img
                :src="currentRecipe.image.url"
                elevation="1"
                class="rounded thumbnail-static"
            >
            </v-img>
          </v-col>
        </v-row>
        <v-divider class="mt-2 mb-2"></v-divider>
        <v-row class="mt-0">
          <v-col cols="6" class="d-flex justify-start pt-0">
            <v-btn
                v-if="currentRecipe.isLiked"
                :loading="likeBtnLoading"
                @click="likeRecepie(currentRecipe.resourceid, false)"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-1
                  me-2
                  mt-1
                  mb-1
                "
                color="#5e72e4"
            ><v-icon
                :elevation="'1'"
                :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
            >fas fa-heart mr-1</v-icon
            >
              Polubione
            </v-btn>
            <v-btn
                v-else
                :loading="likeBtnLoading"
                @click="likeRecepie(currentRecipe.resourceid)"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-1
                  me-2
                  mt-1
                  mb-1
                "
                color="#5e72e4"
            ><v-icon
                :elevation="'1'"
                :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
            >far fa-heart mr-1</v-icon
            >
              Polub
            </v-btn>
          </v-col>
<!--          <v-col-->
<!--              cols="6"-->
<!--              class="d-flex justify-end mt-0 pt-0"-->
<!--              v-if="userHasActivePlan"-->
<!--          >-->
<!--            <v-btn-->
<!--                v-if="-->
<!--                  !diaryDay ||-->
<!--                  diaryDay.meals.find(-->
<!--                    (e) => e.id === currentRecipe.resourceid-->
<!--                  ) === undefined-->
<!--                "-->
<!--                :loading="diaryLoading"-->
<!--                @click="pushDiaryRecipe()"-->
<!--                elevation="0"-->
<!--                :ripple="false"-->
<!--                height="43"-->
<!--                class="-->
<!--                  font-weight-bold-->
<!--                  transparent-->
<!--                  text-typo-->
<!--                  px-1-->
<!--                  me-2-->
<!--                  mt-1-->
<!--                  mb-1-->
<!--                "-->
<!--                color="#5e72e4"-->
<!--            ><v-icon-->
<!--                :elevation="'1'"-->
<!--                :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"-->
<!--            >far fa-calendar-plus mr-1</v-icon-->
<!--            >-->
<!--              Wybieram-->
<!--            </v-btn>-->
<!--            <v-btn-->
<!--                v-else-if="diaryDay"-->
<!--                elevation="0"-->
<!--                :ripple="false"-->
<!--                height="43"-->
<!--                :style="'background-color: rgb(255, 50, 198);'"-->
<!--                class="-->
<!--                  font-weight-bold-->
<!--                  text-white-->
<!--                  btn-primary-->
<!--                  px-6-->
<!--                  me-2-->
<!--                  mt-1-->
<!--                  mb-1-->
<!--                "-->
<!--            ><v-icon-->
<!--                :elevation="'1'"-->
<!--                :style="'color: white; font-size: 21px !important;'"-->
<!--            >fas fa-utensils mr-1</v-icon-->
<!--            >-->
<!--              Wybrane-->
<!--            </v-btn>-->
<!--          </v-col>-->
        </v-row>
        <v-row> </v-row>
        <v-row class="mt-0" v-if="currentRecipe.description">
          <v-col class="pt-0">
            <v-card class="p-1">
              <v-alert
                  dense
                  class="mt-2"
                  :style="'background-color: #EFDFFA;'"
              >
                  <span :style="'color: black'">
                    {{ currentRecipe.description }}
                  </span>
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
        <v-divider
            class="mt-2 mb-2 ml-2 mr-2"
            v-if="currentRecipe.description"
        ></v-divider>
        <v-row class="d-flex justify-center">
          <v-col cols="6" xs="12" class="d-flex justify-center">
            <v-chip
                class="w-100 macro-chip"
                :style="'color: white; background-color: #4B4B4B;'"
            >
              {{ `kcal: ${currentRecipe.kcal}` }}
            </v-chip>
          </v-col>
          <v-col cols="6" xs="12" class="d-flex justify-center">
            <v-chip
                class="w-100 macro-chip"
                :style="'color: white; background-color: #B361FF;'"
            >
              {{ `Białko: ${currentRecipe.macro.protein}g` }}
            </v-chip>
          </v-col>
          <v-col cols="6" xs="12" class="d-flex justify-center">
            <v-chip
                class="w-100 macro-chip"
                :style="'color: white; background-color: #FF8334;'"
            >
              {{ `Tłuszcze: ${currentRecipe.macro.fat}g` }}
            </v-chip>
          </v-col>
          <v-col cols="6" xs="12" class="d-flex justify-center">
            <v-chip
                class="w-100 macro-chip"
                :style="'color: white; font-size: 11px; background-color: #FF32C6;'"
            >
              {{ `Węglowodany: ${currentRecipe.macro.carbs}g` }}
            </v-chip>
          </v-col>
        </v-row>
        <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
        <v-row class="d-flex justify-center mt-0">
          <v-col>
            <template>
              <v-simple-table :style="'background-color: #EFDFFA;'">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-center">
                      <span :style="'opacity: 0 !important;'">#</span>
                    </th>
                    <th
                        class="text-center font-size-hero-medium text-black"
                    >
                      Kroki przygotowania
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(prep, index) in currentRecipe.preparation"
                      :key="index"
                  >
                    <td class="text-left pt-2 pb-2 text-black">
                      {{ `${index + 1}:` }}
                    </td>
                    <td class="text-left pt-2 pb-2 text-black">
                      {{ ` ${prep}` }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-col>
        </v-row>
        <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
        <v-row class="d-flex justify-center mt-0">
          <v-col cols="12" sm="12" md="6" lg="6">
            <template>
              <v-simple-table :style="'background-color: #EFDFFA;'" dense>
                <template v-slot:default>
                  <thead>
                  <tr class="">
                    <th class="text-left">
                      <span :style="'opacity: 0 !important;'">#</span>
                    </th>
                    <th class="text-left font-size-hero-medium text-black">
                      Składniki ({{ currentRecipe.portions }} porcje)
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(ing, index) in currentRecipe.ingredients"
                      :key="index"
                  >
                    <td class="text-left pt-2 pb-2 text-black">
                      {{ `${index + 1}:` }}
                    </td>
                    <td class="text-left pt-2 pb-2 text-black">
                      {{
                        ` ${ing.name} - ${ing.unit_value}${
                            contentConfig.recipe_units[ing.unit].label
                        } ${
                            ing.alt_unit != "none"
                                ? "/ " + ing.alt_unit_value
                                : ""
                        }${
                            ing.alt_unit != "none"
                                ? contentConfig.recipe_alt_units[ing.alt_unit]
                                    .label
                                : ""
                        }`
                      }}
                      <v-spacer></v-spacer>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <template>
              <v-simple-table
                  class=""
                  :style="'background-color: #EFDFFA;'"
                  dense
              >
                <template v-slot:default>
                  <thead v-if="currentRecipe.spices.length > 0">
                  <tr>
                    <th class="text-left">
                      <span :style="'opacity: 0 !important;'">#</span>
                    </th>
                    <th class="text-left font-size-hero-medium text-black">
                      Przyprawy <v-icon>fas fa-pepper-hot</v-icon>
                    </th>
                  </tr>
                  </thead>
                  <thead v-else>
                  <tr>
                    <th
                        class="text-center font-size-hero-medium text-black"
                        colspan="2"
                    >
                      Przyprawy <v-icon>fas fa-pepper-hot</v-icon>
                    </th>
                  </tr>
                  </thead>
                  <tbody v-if="currentRecipe.spices.length > 0">
                  <tr
                      v-for="(ing, index) in currentRecipe.spices"
                      :key="index"
                  >
                    <td class="text-left pt-2 pb-2 text-black">
                      {{ `${index + 1}:` }}
                    </td>
                    <td class="text-left pt-2 pb-2 text-black">
                      {{ ` ${ing.name}` }}
                      <v-spacer></v-spacer>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td class="text-center pt-2 pb-2" colspan="2">
                      {{ `Bez przypraw` }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RecipeDialog',
  props: {
    currentRecipe: Object,
    userHasActivePlan: Boolean,
    value: Boolean,
    diaryDay: Object
  },
  emits: [
      'update:modelValue'
  ],
  computed: {
    show: {
      get () {
        console.log('get', this.value)
        return this.value
      },
      set (value) {
        console.log('set', value)
         this.$emit('input', value)
      }
    }
  },
  data: function () {
    return {
      searchLoading: false,
      pageLoading: true,
      likeBtnLoading: false,
      diaryLoading: false,
    }
  },
  methods: {
    pushDiaryRecipe() {
      this.diaryLoading = true;

      this.$axios
          .post("/api/diary/addmeal", {
            resourceid: this.currentRecipe.resourceid,
            day:this.$getDate()
          })
          .then(async (response) => {
            if (response.data.success) {
              await this.$store.dispatch("fetchUser");
              this.$toast.open({
                message: `<span class="font-weight-bold">Dodano do dzienniczka!</span>`,
                type: "success",
                duration: 3000,
                position: "bottom",
              });
              this.diaryLoading = false;
            } else {
              this.diaryLoading = false;
              this.$toast.open({
                message: `<span class="font-weight-bold">Już dodane do dzienniczka!</span>`,
                type: "success",
                duration: 3000,
                position: "bottom",
              });
            }
          })
          .catch(function (error) {
            this.diaryLoading = false;

            console.log(error);
          });
    },
    loadLikedRevipes: function () {
      this.$axios
          .post("/api/recipes/liked", {
            params: this.selected,
          })
          .then((response) => {
            this.recipes_liked = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },

    likeRecepie: function (resourceid, like = true) {
      this.likeBtnLoading = true;
      this.$axios
          .post(`/api/recipes/${like ? "like" : "unlike"}`, {
            resourceid: resourceid,
          })
          .then((response) => {
            if (response.data.success) {
              this.$set(this.currentRecipe, "isLiked", like);
              this.likeBtnLoading = false;
              this.loadRecipes();
              this.loadLikedRevipes();
            }
          })
          .catch(function (error) {
            this.likeBtnLoading = false;
            console.log(error);
          });
    },
  }
}
</script>

<style scoped>

</style>