<template>
  <v-dialog
      v-model="show"
      v-if="show"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="800"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
  >
    <v-card>
      <v-toolbar class="bg-gradient-primary">
        <v-btn icon @click="trainingDialog = false">
          <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                  ? "fas fa-chevron-left"
                  : "fas fa-times"
            }}</v-icon>
        </v-btn>
        <v-toolbar-title :style="'color: white;'"
        >{{ currentTraining.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container fluid class="p-1">
        <v-row>
          <v-col cols="12">
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                  v-if="currentTraining"
                  class="rounded"
                  :src="`https://player.vimeo.com/video/${currentTraining.vimeo_id}?h=401fac24f8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  "
                  title=""
              ></iframe>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="d-flex justify-start pt-0">
            <v-btn
                v-if="currentTraining.isLiked"
                :loading="likeBtnLoading"
                @click="likeRecepie(currentTraining.resourceid, false)"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-6
                  me-2
                  mt-1
                  mb-1
                "
                color="#5e72e4"
            ><v-icon
                :elevation="'1'"
                :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
            >fas fa-heart mr-3</v-icon
            >
              Polubione
            </v-btn>
            <v-btn
                v-else
                :loading="likeBtnLoading"
                @click="likeTraining(currentTraining.resourceid)"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-6
                  me-2
                  mt-1
                  mb-1
                "
                color="#5e72e4"
            ><v-icon
                :elevation="'1'"
                :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
            >far fa-heart mr-3</v-icon
            >
              Polub
            </v-btn>
          </v-col>

          <v-col class="d-flex justify-end align-center pt-0 mr-4">
            <DifficultyStars
                v-if="currentTraining"
                :tooltip="true"
                :color="'#FF32C6'"
                :rating="currentTraining.difficulty[0]"
            ></DifficultyStars>
          </v-col>
        </v-row>
<!--        <v-row v-if="userHasActivePlan">-->
<!--          <v-col class="d-flex justify-start mt-0 pt-0">-->
<!--            <v-btn-->
<!--                v-if="-->
<!--                  !diaryDay ||-->
<!--                  diaryDay.trainings.find(-->
<!--                    (e) => e.id === currentTraining.resourceid-->
<!--                  ) === undefined-->
<!--                "-->
<!--                :loading="diaryLoading"-->
<!--                @click="pushDiary()"-->
<!--                elevation="0"-->
<!--                :ripple="false"-->
<!--                height="43"-->
<!--                class="font-weight-bold transparent text-typo px-6 me-2 mb-1"-->
<!--                color="#5e72e4"-->
<!--            ><v-icon-->
<!--                :elevation="'1'"-->
<!--                :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"-->
<!--            >far fa-calendar-plus mr-3</v-icon-->
<!--            >-->
<!--              Wybieram-->
<!--            </v-btn>-->
<!--            <v-btn-->
<!--                v-else-if="diaryDay"-->
<!--                elevation="0"-->
<!--                :ripple="false"-->
<!--                height="43"-->
<!--                :style="'background-color: rgb(255, 50, 198);'"-->
<!--                class="-->
<!--                  font-weight-bold-->
<!--                  text-white-->
<!--                  btn-primary-->
<!--                  px-6-->
<!--                  me-2-->
<!--                  mt-1-->
<!--                  mb-1-->
<!--                "-->
<!--            ><v-icon-->
<!--                :elevation="'1'"-->
<!--                :style="'color: white; font-size: 21px !important;'"-->
<!--            >fas fa-heartbeat mr-1</v-icon-->
<!--            >-->
<!--              Wykonany-->
<!--            </v-btn>-->
<!--          </v-col>-->
<!--        </v-row>-->
        <v-row class="mt-0">
          <v-col class="pt-0">
            <v-card class="p-1">
              <v-alert
                  dense
                  class="mt-2"
                  :style="'background-color: #EFDFFA;'"
              >
                  <span :style="'color: black'">
                    {{ currentTraining.description }}
                  </span>
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0">
            <v-card class="p-1">
              <v-alert
                  dense
                  class="mt-2"
                  :style="'background-color: #EFDFFA;'"
              >
                <h4 class="text-typo text-black">
                  <v-icon>fas fa-futbol mr-2</v-icon> Sprzęt
                </h4>
                <span :style="'color: black'">
                    <ul>
                      <li v-for="tool in currentTraining.tools" :key="tool">
                        {{
                          tools.find((tl) => {
                            return tl.value == tool;
                          }).label
                        }}
                      </li>
                    </ul>
                  </span>
              </v-alert>
            </v-card>
          </v-col>
          <v-col class="pt-0">
            <v-card class="p-1">
              <v-alert
                  dense
                  class="mt-2"
                  :style="'background-color: #EFDFFA;'"
              >
                <h4 class="text-typo text-black">
                  <v-icon>fas fa-child mr-2</v-icon> Części ciała
                </h4>
                <span :style="'color: black'">
                    <ul>
                      <li v-for="bp in currentTraining.body_parts" :key="bp">
                        {{
                          body_parts.find((part) => {
                            return part.value == bp;
                          }).label
                        }}
                      </li>
                    </ul>
                  </span>
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  name: 'TrainingDialog',
  props: {
    currentTraining: Object,
    userHasActivePlan: Boolean,
    value: Boolean,
    diaryDay: Object
  },
  emits: [
    'update:modelValue'
  ],
  computed: {
    show: {
      get () {
        console.log('get', this.value)
        return this.value
      },
      set (value) {
        console.log('set', value)
        this.$emit('input', value)
      }
    }
  },
  data: function () {
    return {
      searchLoading: false,
      pageLoading: true,
      likeBtnLoading: false,
      diaryLoading: false,
      free: [],
      recommended: [],
      categories: [],
      types: [],
      lengths: [],
      difficulty: [],
      body_parts: [],
      tools: [],
    }
  },
  mounted() {
    this.loadFilters();
    // this.loadTrainings();
    // this.loadLiked();
  },
  methods: {
    loadLikedTrainings: function () {
      this.$axios
          .post("/api/trainings/liked", {
            params: this.selected,
          })
          .then((response) => {
            this.trainings_liked = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    loadFilters: function () {
      this.$axios
          .post("/api/trainings/filters", {
            params: this.$_.omit(this.selected, "types"),
          })
          .then((response) => {
            this.free = response.data.free;
            this.recommended = response.data.recommended;
            this.categories = response.data.categories;
            this.types = response.data.types;
            this.lengths = response.data.lengths;
            this.difficulty = response.data.difficulty;
            this.body_parts = response.data.body_parts;
            this.tools = response.data.tools;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    likeTraining: function (resourceid, like = true) {
      this.likeBtnLoading = true;
      this.$axios
          .post(`/api/trainings/${like ? "like" : "unlike"}`, {
            resourceid: resourceid,
          })
          .then((response) => {
            if (response.data.success) {
              this.$set(this.currentTraining, "isLiked", like);
              this.likeBtnLoading = false;
              this.loadTrainings();
              this.loadLikedTrainings();
            }
          })
          .catch(function (error) {
            this.likeBtnLoading = false;
            console.log(error);
          });
    },
  }
}
</script>

<style scoped>

</style>