<template>
  <v-container
    class="rounded mt-4"
    :style="'background-color: #f7e1ff !important; max-width: 1000px;'"
  >
    <v-row>
      <v-col class="d-flex justify-center">
        <v-date-picker
          v-model="picker_date"
          elevation="15"
          :color="'#B361FF'"
          :locale="'pl'"
        ></v-date-picker>
      </v-col>
      <v-col class="d-flex justify-center">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-card
                class="
                  w-100
                  rounded
                  d-flex
                  align-center
                  justify-center
                  pa-4
                  transparent
                "
              >
                <vc-donut
                  :sections="chart_data"
                  :thickness="32"
                  :background="'#f7e1ff'"
                  ><h2
                    v-if="diaryDay.meals.length > 0"
                    class="text-typo monteserrat500"
                    style="margin: 0"
                  >
                    {{ getConsumedKcal() }}/{{ diaryDay.kcalTarget }} kcal
                  </h2>
                  <h2 class="text-typo monteserrat500" style="margin: 0" v-else>
                    -/{{ user.userConfig.kcal }}
                  </h2>
                </vc-donut>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h4 class="text-typo text-center">Nawodnienie</h4>
            </v-col>
            <v-col class="d-flex justify-center mb-2">
              <WaterPicker
                v-if="diaryDay.water > -1"
                @setWater="setDiaryWater"
                :cups="diaryDay.water"
                :cupsMax="diaryDay.waterTarget"
              ></WaterPicker>
              <WaterPicker
                v-else
                @setWater="setDiaryWater"
                :cups="0"
                :cupsMax="user.userConfig.water / 300"
              ></WaterPicker>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mt-2">
            <v-col class="d-flex justify-center">
              <v-btn
                :ripple="false"
                elevation="1"
                color="#fff"
                class="font-weight-bolder font-size-hero-medium py-4 px-7 ms-2"
                :class="'btn-primary button-primary-gradient'"
                :style="'text-transform: none;'"
                rounded
                large
                @click="recipeDialog = true"
              >
                <v-icon
                  size="18px"
                  :style="'transform: translateY(1px);'"
                  class="mr-3"
                  >fas fa-plus-circle</v-icon
                >
                Dodaj kalorie
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-btn
                :ripple="false"
                elevation="1"
                color="#fff"
                class="font-weight-bolder font-size-hero-medium py-4 px-7 ms-2"
                :class="'btn-primary button-primary-gradient'"
                :style="'text-transform: none;'"
                rounded
                large
                @click="trainingDialog = true"
              >
                <v-icon
                  size="18px"
                  :style="'transform: translateY(1px);'"
                  class="mr-3"
                  >fas fa-plus-circle</v-icon
                >
                Inny trening
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="6">
        <template>
          <v-simple-table :style="'background-color: rgb(251, 246, 255);'">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-center font-size-hero-medium text-black"
                    colspan="3"
                  >
                    Treningi
                  </th>
                </tr>
              </thead>
              <tbody v-if="tableTR.length > 0">
                <tr
                  v-for="(entry, index) in tableTR"
                  :key="`${entry.id}${index}`"
                >
                <v-container fluid>
                    <v-row>
                      <v-col class="d-flex justify-center align-center pa-1">
                        <v-container fluid
                        >
                          <v-row>
                            <v-col
                            cols="12"
                              class="d-flex justify-center align-center pa-1"
                            >
                              <v-img
                                v-if="entry.image !== 'custom'"
                                :src="entry.image"
                                contain
                                :style="'max-width: 100px;'"
                                class="rounded"
                              >
                              </v-img>
                              <v-img
                                v-else
                                src="@/assets/img/custom_training.png"
                                contain
                                :style="'max-width: 100px;'"
                                class="rounded"
                              >
                              </v-img>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                            cols="12"
                              class="d-flex justify-center align-center pa-1 "
                              style="cursor: pointer"
                              @click="openTrainingView(entry.id)"
                            >
                              <h4 class="text-typo text-center">{{ entry.name }}</h4>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col class="d-flex justify-center align-center pa-1">
                        <v-btn
                          class="btn-primary button-primary-gradient"
                          icon
                          @click="deleteFromDiary(entry.id, 'training')"
                        >
                          <v-icon :style="'color: white;'" class="ma-2">
                            fas fa-trash-alt
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                  <!-- <td
                    class="text-left pt-2 pb-2 text-black"
                    :style="'max-width: 100px !important;'"
                  >
                    <v-img
                      v-if="entry.image !== 'custom'"
                      :src="entry.image"
                      contain
                      :style="'max-width: 100px;'"
                      class="rounded"
                    >
                    </v-img>
                    <v-img
                      v-else
                      src="@/assets/img/custom_training.png"
                      contain
                      :style="'max-width: 100px;'"
                      class="rounded"
                    >
                    </v-img>
                  </td>
                  <td class="text-left pt-2 pb-2 text-black">
                    <h2 class="text-typo">{{ entry.name }}</h2>
                  </td>
                  <td>
                    <v-btn
                      class="btn-primary button-primary-gradient"
                      icon
                      @click="deleteFromDiary(entry.id, 'training')"
                    >
                      <v-icon :style="'color: white;'" class="ma-2">
                        fas fa-trash-alt
                      </v-icon>
                    </v-btn>
                  </td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <template>
          <v-simple-table :style="'background-color: rgb(251, 246, 255);'">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-center font-size-hero-medium text-black"
                    colspan="3"
                  >
                    Twoje Menu
                  </th>
                </tr>
              </thead>
              <tbody v-if="tableME.length > 0">
                <tr
                  v-for="(entry, index) in tableME"
                  :key="`${entry.id}${index}`"
                >
                  <v-container fluid>
                    <v-row>
                      <v-col class="d-flex justify-center align-center pa-1">
                        <v-container fluid
                                     @click="openRecipeView(entry.id)"
                                     style="cursor: pointer"
                        >
                          <v-row>
                            <v-col
                            cols="12"
                              class="d-flex justify-center align-center pa-1"
                            >
                              <v-img
                                v-if="entry.image !== 'custom'"
                                :src="entry.image"
                                contain
                                :style="'max-width: 100px;'"
                                class="rounded"
                              >
                              </v-img>
                              <v-img
                                v-else
                                src="@/assets/img/custom_meal.png"
                                contain
                                :style="'max-width: 100px;'"
                                class="rounded"
                              >
                              </v-img>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                            cols="12"
                              class="d-flex justify-center align-center pa-1"
                            >
                              <h4 class="text-typo text-center">{{ entry.name }}</h4>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col class="d-flex justify-center align-center pa-1">
                        <v-btn
                            class="btn-primary button-primary-gradient"
                            icon
                            @click="modifyDiary(entry.id, 'meal', entry.multiple + 1)"
                        >
                          <v-icon :style="'color: white;'" class="ma-2">
                            fas fa-plus
                          </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col class="d-flex justify-center align-center pa-1">
                          x {{entry.multiple}}
                      </v-col>
                      <v-col class="d-flex justify-center align-center pa-1">
                        <v-btn
                          class="btn-primary button-primary-gradient"
                          icon
                          @click="deleteFromDiary(entry.id, 'meal')"
                          v-if="entry.multiple <= 1"
                        >
                          <v-icon :style="'color: white;'" class="ma-2">
                            fas fa-trash-alt
                          </v-icon>
                        </v-btn>
                        <v-btn
                            class="btn-primary button-primary-gradient"
                            icon
                            @click="modifyDiary(entry.id, 'meal', entry.multiple - 1)"
                            v-else
                        >
                          <v-icon :style="'color: white;'" class="ma-2">
                            fas fa-minus
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>

                  <!-- <td
                    class="text-left pt-2 pb-2 text-black"
                    :style="'max-width: 100px !important;'"
                  >
                    <v-img
                      v-if="entry.image !== 'custom'"
                      :src="entry.image"
                      contain
                      :style="'max-width: 100px;'"
                      class="rounded"
                    >
                    </v-img>
                    <v-img
                      v-else
                      src="@/assets/img/custom_meal.png"
                      contain
                      :style="'max-width: 100px;'"
                      class="rounded"
                    >
                    </v-img>
                  </td>
                  <td class="text-left pt-2 pb-2 text-black">
                    <h2 class="text-typo">{{ entry.name }}</h2>
                  </td>
                  <td>
                    <v-btn
                      class="btn-primary button-primary-gradient"
                      icon
                      @click="deleteFromDiary(entry.id, 'meal')"
                    >
                      <v-icon :style="'color: white;'" class="ma-2">
                        fas fa-trash-alt
                      </v-icon>
                    </v-btn>
                  </td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </v-col>
    </v-row>
    <v-dialog
      v-model="trainingDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="400"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on"> Open Dialog </v-btn>
      </template> -->
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="trainingDialog = false">
            <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                ? "fas fa-chevron-left"
                : "fas fa-times"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'"
            >Dodaj trening</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
              <v-btn dark text @click="dialog = false"> Save </v-btn>
            </v-toolbar-items> -->
        </v-toolbar>
        <v-container class="p-4">
          <v-alert dense class="bg-gradient-info">
            <span :style="'color: white'">
              <v-icon :style="'color: white'">fas fa-info-circle</v-icon>
              Podaj nazwę własnej aktywności fizycznej
            </span>
          </v-alert>
          <v-row class="d-flex justify-center">
            <v-col cols="12" class="pb-0">
              <h4 class="text-center text-typo montserrat500">
                Nazwa aktywności
              </h4>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <v-text-field
                hide-details
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                placeholder="Nazwa"
                v-model="custom_training_name"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-2
                "
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-primary
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-1
              mb-2
              w-100
            "
            :loading="addCustomLoading"
            @click="pushCustomTraining"
            color="#5e72e4"
            ><v-icon>fas fa-plus-circle</v-icon>
            <span class="ml-1">Dodaj</span>
          </v-btn>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="recipeDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="400"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on"> Open Dialog </v-btn>
      </template> -->
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="recipeDialog = false">
            <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                ? "fas fa-chevron-left"
                : "fas fa-times"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'"
            >Dodaj kalorie</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
              <v-btn dark text @click="dialog = false"> Save </v-btn>
            </v-toolbar-items> -->
        </v-toolbar>
        <v-container class="p-4">
          <v-row>
            <v-col>
              <v-alert dense class="bg-gradient-info">
                <span :style="'color: white'">
                  <v-icon :style="'color: white'">fas fa-info-circle</v-icon>
                  Podaj nazwę oraz kaloryczność swojego posiłku
                </span>
              </v-alert>
            </v-col>
          </v-row>

          <v-row class="d-flex justify-center">
            <v-col cols="12" class="pb-0">
              <h4 class="text-center text-typo montserrat500">Nazwa posiłku</h4>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <v-text-field
                hide-details
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                placeholder="Nazwa"
                v-model="custom_recipe_name"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-2
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="12" class="pb-0">
              <h4 class="text-center text-typo montserrat500">kcal</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                hide-details
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                :type="'number'"
                height="40"
                placeholder="kcal"
                v-model="custom_recipe_kcal"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-2
                "
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-primary
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-1
              mb-2
              w-100
            "
            :loading="addCustomLoading"
            @click="pushCustomMeal"
            color="#5e72e4"
            ><v-icon>fas fa-plus-circle</v-icon>
            <span class="ml-1">Dodaj</span>
          </v-btn>
        </v-container>
      </v-card>
    </v-dialog>
    <RecipeDialog :current-recipe="this.currentRecipe"
                  v-model="recipeDialogView"
                  v-if="recipeDialogView"
                  :user-has-active-plan="this.userHasActivePlan"
    />
    <TrainingDialog :current-training="this.currentTraining"
                  v-model="trainingDialogView"
                  v-if="trainingDialogView"
                  :user-has-active-plan="this.userHasActivePlan"
    />
  </v-container>
</template>

<script>
import WaterPicker from "@/components/util/WaterPicker.vue";
import RecipeDialog from '@/views/RecipeDialog'
import TrainingDialog from '@/views/TrainingDialog'
export default {
  name: "Diary",
  data() {
    return {
      picker_date: this.$getDate(),
      diaryDay: {
        trainings: [],
        meals: [],
        water: -1,
      },
      tableTR: [],
      tableME: [],
      chart_data: [
        { value: 70, color: "#B361FF" },
        { value: 30, color: "#FFB300" },
      ],
      custom_training_name: "",
      trainingDialog: false,
      custom_recipe_name: "",
      custom_recipe_kcal: 0,
      recipeDialog: false,
      addCustomLoading: false,
      deleteLoading: false,

      all_recipes: [],
      all_trainings: [],
      recipeDialogView: false,
      trainingDialogView: false,
      currentRecipe: null,
      currentTraining: null,
    };
  },
  components: {
    WaterPicker,
    RecipeDialog,
    TrainingDialog,
  },
  methods: {
    setEmptyDay: function () {
      this.diaryDay = {
        trainings: [],
        meals: [],
        water: -1,
      };
      (this.tableTR = []), (this.tableME = []);
    },
    getDate: function (date) {
      return (
        date.getFullYear() +
        "-" +
        ((date.getMonth() + 1).toString().length == 1
          ? `0${(date.getMonth() + 1).toString()}`
          : `${(date.getMonth() + 1).toString()}`) +
        "-" +
        (date.getDate().toString().length == 1
          ? `0${date.getDate().toString()}`
          : `${date.getDate().toString()}`)
      );
    },
    loadRecipes: function (search = false) {
      // if (search) {
      //   this.searchLoading = true;
      // }
      this.$axios
          .post("/api/recipes/filtered", {
            params: { ...this.selected,
            },
          })
          .then((response) => {
            // this.recipes = response.data.filtered;
            // this.loadPagesRecipe(response.data.filtered.length);
            this.all_recipes = response.data.all;
            // this.pageLoading = false;
            // this.searchLoading = false;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getConsumedKcal: function () {
      let sum = 0;
      this.diaryDay.meals.forEach((meal) => {
        sum += meal.kcal * (meal.multiple || 1);
      });
      return sum;
    },
    getDiaryDay: function () {
      this.$axios
        .post("/api/diary/getday", {
          day: this.picker_date,
        })
        .then((response) => {
          if (response.data.success) {
            this.diaryDay = response.data.day;
            this.tableTR = response.data.day.trainings;
            this.tableME = response.data.day.meals;
            this.updateDonut();
          } else {
            this.setEmptyDay();
          }
          this.updateDonut();
        })
        .catch((error) => {
          console.log(error);
          this.setEmptyDay();
          this.updateDonut();
        });
    },
    setDiaryWater(amount) {
      this.$axios
        .post("/api/diary/setwater", {
          day: this.picker_date,
          water: amount,
        })
        .then((response) => {
          if (response.data.success) {
            this.getDiaryDay();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pushCustomTraining: function () {
      if (this.custom_training_name.length < 3) {
        this.$toast.open({
          message: `<span class="font-weight-bold">Podaj prawidłową nazwę aktywności (min. 3 znaki)!</span>`,
          type: "error",
          duration: 3000,
          position: "bottom",
        });
        return;
      }
      this.addCustomLoading = true;
      this.$axios
        .post("/api/diary/customtraining", {
          day: this.picker_date,
          name: this.custom_training_name,
        })
        .then(async (response) => {
          if (response.data.success) {
            this.getDiaryDay();
            this.addCustomLoading = false;
            this.trainingDialog = false;
            await this.$store.dispatch("fetchUser");
          } else {
            this.setEmptyDay();
            this.addCustomLoading = false;
          }
        })
        .catch((error) => {
          this.addCustomLoading = false;
          console.log(error);
        });
    },
    pushCustomMeal: function () {
      if (
        isNaN(this.custom_recipe_kcal) ||
        parseInt(this.custom_recipe_kcal) < 1
      ) {
        this.$toast.open({
          message: `<span class="font-weight-bold">Podaj prawidłową wartość kcal!</span>`,
          type: "error",
          duration: 3000,
          position: "bottom",
        });
        return;
      }
      if (this.custom_recipe_name.length < 3) {
        this.$toast.open({
          message: `<span class="font-weight-bold">Podaj prawidłową nazwę posiłku (min. 3 znaki)!</span>`,
          type: "error",
          duration: 3000,
          position: "bottom",
        });
        return;
      }
      this.addCustomLoading = true;
      this.$axios
        .post("/api/diary/custommeal", {
          day: this.picker_date,
          name: this.custom_recipe_name,
          kcal: this.custom_recipe_kcal,
        })
        .then(async (response) => {
          if (response.data.success) {
            this.getDiaryDay();
            this.addCustomLoading = false;
            this.recipeDialog = false;
            await this.$store.dispatch("fetchUser");
          } else {
            this.setEmptyDay();
            this.addCustomLoading = false;
          }
        })
        .catch((error) => {
          this.addCustomLoading = false;
          console.log(error);
        });
    },
    deleteFromDiary: function (id, type) {
      this.deleteLoading = true;
      this.$axios
        .post(`/api/diary/delete${type}`, {
          day: this.picker_date,
          resourceid: id,
        })
        .then(async (response) => {
          if (response.data.success) {
            this.getDiaryDay();
            await this.$store.dispatch("fetchUser");
          }
          this.deleteLoading = false;
        })
        .catch((error) => {
          this.deleteLoading = false;
          console.log(error);
        });
    },
    modifyDiary: function (id, type, newCount) {
      this.deleteLoading = true;
      this.$axios
          .post(`/api/diary/modify${type}`, {
            day: this.picker_date,
            newCount,
            resourceid: id,
          })
          .then(async (response) => {
            if (response.data.success) {
              this.getDiaryDay();
              await this.$store.dispatch("fetchUser");
            }
            this.deleteLoading = false;
          })
          .catch((error) => {
            this.deleteLoading = false;
            console.log(error);
          });
    },
    updateDonut: function () {
      if (!this.diaryDay.meals.length > 0) {
        this.chart_data[0].value = 0;
        this.chart_data[1].value = 100;
        return;
      }
      let consumed_kcal = this.getConsumedKcal();

      this.chart_data[0].value = Math.ceil(
        (consumed_kcal / this.user.userConfig.kcal) * 100
      );
      if (this.chart_data[0].value > 100) {
        this.chart_data[0].value = 100;
      }
      this.chart_data[1].value = 100 - this.chart_data[0].value;
    },
    openRecipeView(id) {
      let chosenRecipe = this.all_recipes.find((recipe) => {
        return recipe.resourceid === id;
      });

      if (
          (chosenRecipe.is_free || this.userHasActivePlan) &&
          !this.$store.getters.contentLockedDialog
      ) {
        this.currentRecipe = chosenRecipe;
        this.recipeDialogView = true;
        console.log('recipeDialogView', this.recipeDialogView)
      } else if (!this.recipeDialogView) {
        this.$store.commit("setContentLockedDialog", true);
      }
    },
    closeRecipe() {
      this.currentRecipe = null;
      this.recipeDialogView = false;
    },
    loadTrainings: function () {
      this.$axios
          .post("/api/trainings/filtered", {
            params: {},
          })
          .then((response) => {
            // this.trainings = response.data.filtered;
            // this.loadPages(response.data.filtered.length);
            this.all_trainings = response.data.all;
            // this.pageLoading = false;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    openTrainingView(id) {
      let chosenTraining = this.all_trainings.find((training) => {
        return training.resourceid === id;
      });

      if (
          (chosenTraining.is_free || this.userHasActivePlan) &&
          !this.$store.getters.contentLockedDialog
      ) {
        this.currentTraining = chosenTraining;
        this.trainingDialogView = true;
      } else if (!this.trainingDialogView) {
        this.$store.commit("setContentLockedDialog", true);
      }
    },
  },
  mounted() {
    this.setEmptyDay();
    this.picker_date = this.$getDate();
    this.getDiaryDay();

    this.loadRecipes();

    this.loadTrainings();

      // this.loadFilters();
      // this.loadFiltersTrainings();
      // this.loadLikedRevipes();
      // this.loadLikedTrainings();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    userHasActivePlan() {
      return this.$store.state.userHasActivePlan;
    },
  },
  watch: {
    diaryDay: {
      handler: function () {},
    },
    picker_date:{
      handler:function(){
        this.getDiaryDay()
      }
    }
  },
};
</script>

<style>
</style>